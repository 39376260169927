import React, { Component, Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { agencyTheme } from 'common/src/theme/agency';
import {
  GlobalStyle,
  AgencyWrapper,
} from '../../containers/Agency/agency.style';
import { ResetCSS } from '../../../../common/src/assets/css/style';
import Navbar from '../../containers/Agency/Navbar';
import BannerSection from '../../containers/Agency/BannerSection';
import Footer from '../../containers/Agency/Footer';
import { DrawerProvider } from '../../../../common/src/contexts/DrawerContext';
import '@redq/reuse-modal/es/index.css';
import SEO from '../../components/seo';

class Home extends Component {
  render() {
    const context = this.props.pageContext;

    const title = {
      content: context.headline,
      fontSize: ['26px', '30px', '30px', '48px', '60px'],
      fontWeight: '300',
      color: '#0f2137',
      letterSpacing: '-0.01px',
      mb: '20px',
    };
    const description = {
      content: context.content.json,
    };

    return (
      <ThemeProvider theme={agencyTheme}>
        <Fragment>
          <SEO title="Agency" />
          <ResetCSS />
          <GlobalStyle />
          {/* End of agency head section */}
          {/* Start agency wrapper section */}
          <AgencyWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar
                  project={context.project}
                  menuItems={context.menuItems}
                />
              </DrawerProvider>
            </Sticky>
            <BannerSection
              title={title}
              description={description}
              project={context.project}
              legalMenu={context.legalMenu}
            />
            <Footer
              project={context.project}
              copyright={context.copyright}
              menuItems={context.menuItems}
            />
          </AgencyWrapper>
          {/* End of agency wrapper section */}
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default Home;
